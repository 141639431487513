import { Navigate } from 'react-router-dom';
import { useUserRole } from '../../context/RoleContext/useUserRole';
import { UserRole } from '../../models/general.models';
import { AppRoutes, DashboardRoutes } from '../routes';
import { FC } from '../../types/general.types';

type AuthenticationRouteProps = {
  userRoles: UserRole[];
  redirectPage: AppRoutes | DashboardRoutes;
};

const RouteAuthorizer: FC<AuthenticationRouteProps> = ({
  userRoles,
  redirectPage,
  children,
}) => {
  const { userRole } = useUserRole();

  const isAuthorized = userRoles.includes(userRole);

  return isAuthorized ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectPage} replace />
  );
};

export default RouteAuthorizer;
