import React from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { UserRole } from '../models/general.models';
import LeaderboardPage from '../pages/leaderboard/leaderboard.page';
import RouteAuthorizer from './components/AuthenticationRoute';
import {
  AppRoutes,
  DashboardRoutes,
  ManageTeamRoutes,
  ManageUsersRoutes,
} from './routes';

const CreateCompanyPage = React.lazy(
  () => import('../pages/createCompany/createCompany.page')
);
const NoCompanyPage = React.lazy(
  () => import('../pages/noCompany/noCompany.page')
);
const DashboardPage = React.lazy(
  () => import('../pages/dashboard/dashboard.page')
);
const HomePage = React.lazy(() => import('../pages/home/home.page'));
const ManageUsersPage = React.lazy(
  () => import('../pages/manageUsers/manageUsers.page')
);
const ManageUserPage = React.lazy(
  () => import('../pages/manageUser/manageUser.page')
);
const ManageTeamsPage = React.lazy(
  () => import('../pages/manageTeams/manageTeams.page')
);
const ManageTeamPage = React.lazy(
  () => import('../pages/manageTeam/manageTeam.page')
);
const MyCompaniesPage = React.lazy(
  () => import('../pages/myCompanies/myCompanies.page')
);
const AnalyticsPage = React.lazy(
  () => import('../pages/analytics/analytics.page')
);
const ManageCompanyPage = React.lazy(
  () => import('../pages/manageCompany/manageCompany.page')
);
const CompanyActionPage = React.lazy(
  () => import('../pages/companyActions/companyActions.page')
);

const dashboardRoutes: RouteObject[] = [
  {
    path: AppRoutes.Base,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin]}
        redirectPage={AppRoutes.Autenthication}
      >
        <Navigate to={AppRoutes.MyCompanies} replace />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.NoCompany,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.User]}
        redirectPage={AppRoutes.Base}
      >
        <NoCompanyPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.CreateCompany,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin, UserRole.User]}
        redirectPage={AppRoutes.Autenthication}
      >
        <CreateCompanyPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.MyCompanies,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin]}
        redirectPage={AppRoutes.Autenthication}
      >
        <MyCompaniesPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.Dashboard,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Admin]}
        redirectPage={AppRoutes.Autenthication}
      >
        <DashboardPage />
      </RouteAuthorizer>
    ),
    children: [
      {
        path: `${DashboardRoutes.Home}`,
        element: <HomePage />,
      },
      {
        path: `${DashboardRoutes.ManageUsers}`,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <ManageUsersPage />,
          },
          {
            path: `${ManageUsersRoutes.ManageUser}`,
            element: <ManageUserPage />,
          },
        ],
      },
      {
        path: `${DashboardRoutes.ManageTeams}`,
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <ManageTeamsPage />,
          },
          {
            path: ManageTeamRoutes.ManageTeam,
            element: <ManageTeamPage />,
          },
        ],
      },
      {
        path: `${DashboardRoutes.Analytics}`,
        element: <AnalyticsPage />,
      },
      {
        path: `${DashboardRoutes.CompanyActions}`,
        element: <CompanyActionPage />,
      },
      {
        path: `${DashboardRoutes.ManageCompany}`,
        element: <ManageCompanyPage />,
      },
      {
        path: `${DashboardRoutes.Leaderboard}`,
        element: <LeaderboardPage />,
      },
      {
        path: `${DashboardRoutes.Challenge}`,
        element: <LeaderboardPage initialTab="challenge" />,
      },
      {
        path: '',
        element: <Navigate to={DashboardRoutes.Home} replace />,
      },
    ],
  },
];

export default dashboardRoutes;
