export enum ManageUsersRoutes {
  ManageUser = ':userId',
}

export enum ManageTeamRoutes {
  ManageTeam = ':teamId',
}

export enum DashboardRoutes {
  ManageUsers = 'manage-users',
  ManageTeams = 'manage-teams',
  ManageCompany = 'manage-company',
  ManageTeam = 'manage-teams/:teamId',
  Analytics = 'analytics',
  Leaderboard = 'leaderboard',
  Challenge = 'challenge',
  CompanyActions = 'actions',
  Home = 'home',
}

export enum AppRoutes {
  Base = '/',
  Autenthication = '/auth',
  AuthCode = '/auth/code',
  NoCompany = '/no-company',
  CreateCompany = '/company/new',
  Dashboard = '/dashboard/:companyId',
  DashboardHome = '/dashboard/:companyId/home',
  DashboardManageUser = '/dashboard/:companyId/manage-users/:userId',
  DashboardManageUsers = '/dashboard/:companyId/manage-users',
  DashboardManageTeams = '/dashboard/:companyId/manage-teams',
  DashboardAnalytics = '/dashboard/:companyId/analytics',
  DashboardManageCompany = '/dashboard/:companyId/manage-company',
  DashboardManageTeam = '/dashboard/:companyId/manage-teams/:teamId',
  DashboardLeaderboard = '/dashboard/:companyId/leaderboard',
  DashboardCompanyActions = '/dashboard/:companyId/actions',
  MyCompanies = '/my-companies',
  Profile = '/profile',
  EditProfile = '/profile/edit',
}

export const createDashboardUserLink = (companyId: string, userId: string) => {
  return AppRoutes.DashboardManageUser.replace(':companyId', companyId).replace(
    ':userId',
    userId
  );
};

export const createDashboardTeamLink = (companyId: string, teamId: string) => {
  return AppRoutes.DashboardManageTeam.replace(':companyId', companyId).replace(
    ':teamId',
    teamId
  );
};
