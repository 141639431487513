import React, { memo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';

type SideNavbarLinkProps = {
  to: string;
  link: string;
  icon: React.ReactNode;
};

const SideNavbarLink: React.FC<SideNavbarLinkProps> = memo(
  ({ link, to, icon }) => {
    const navLinkClassGenerator = useCallback(
      ({ isActive }: { isActive: boolean }) =>
        `hover:underline mt-3 justify-start items-center py-2 px-6 w-full flex ${
          isActive ? 'text-primary bg-light-green rounded-full font-medium' : ''
        }`,
      []
    );

    return (
      <NavLink className={navLinkClassGenerator} to={to}>
        <div className="mr-3">{icon}</div>
        {link}
      </NavLink>
    );
  }
);

export default SideNavbarLink;
