import { createNotImplementedMethod } from '../utils';
import {
  GetListBurnoutAlertsRequest,
  Alert,
} from './alerts.models';

export interface IAlertsApi {
  readonly getListBurnoutAlerts: (
    req: GetListBurnoutAlertsRequest
  ) => Promise<Alert[]>;
}

export class NotImplementedAlertsApi implements IAlertsApi {
  public readonly getListBurnoutAlerts = createNotImplementedMethod();
}
