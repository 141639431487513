import React from 'react';
import { RouteObject } from 'react-router-dom';
import { UserRole } from '../models/general.models';
import AuthCodePage from '../pages/authCode/authCode.page';
import RouteAuthorizer from './components/AuthenticationRoute';
import { AppRoutes } from './routes';

const AuthenticationPage = React.lazy(
  () => import('../pages/authentication/authentication.page')
);

const authenticationRoutes: RouteObject[] = [
  {
    path: AppRoutes.Autenthication,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Anonymous]}
        redirectPage={AppRoutes.NoCompany}
      >
        <AuthenticationPage />
      </RouteAuthorizer>
    ),
  },
  {
    path: AppRoutes.AuthCode,
    element: (
      <RouteAuthorizer
        userRoles={[UserRole.Anonymous]}
        redirectPage={AppRoutes.NoCompany}
      >
        <AuthCodePage />
      </RouteAuthorizer>
    ),
  },
];

export default authenticationRoutes;
