import { IHttpClient } from '../../services/HttpClient/IHttpClient';

import { IAlertsApi } from './IAlertsApi';
import {
  GetListBurnoutAlertsRequest,
  GetListBurnoutAlertsResponse,
} from './alerts.models';

export class AlertsApi implements IAlertsApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getListBurnoutAlerts = async (
    req: GetListBurnoutAlertsRequest
  ) => {
    const res = await this.httpClient.get<GetListBurnoutAlertsResponse>(
      `/core/company/${req.companyId}/burnoutAlerts`,
      {
        isAuthorized: true,
        params: {
          entityFilter: req.entityFilter,
        },
      }
    );
    return res.items;
  };
}
