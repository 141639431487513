import React, { createContext, useEffect, useState } from 'react';
import {
  IAuthorizationApi,
  NotImplementedAuthorizationApi,
} from '../../api/authorization/IAuthorizationApi';
import {
  ICompanyApi,
  NotImplementedCompanyApi,
} from '../../api/company/ICompanyApi';
import { createApi } from '../../api/createApi';
import {
  IFeedbackApi,
  NotImplementedFeedbackApi,
} from '../../api/feedback/IFeedbackApi';
import {
  IGamificationApi,
  NotImplementedGamificationApi,
} from '../../api/gamification/IGamificationApi';
import { ITeamApi, NotImplementedTeamApi } from '../../api/team/ITeamApi';
import { IUserApi, NotImplementedUserApi } from '../../api/user/IUserApi';
import { Config } from '../../config';
import { useErrorHandler } from '../ErrorHandlerContext/useErrorHandler';
import { useStorageProvider } from '../StorageProviderContext/useStorageProvider';
import {
  ICompanyActionApi,
  NotImplementedCompanyActionApi,
} from '../../api/companyAction/ICompanyActionApi';
import {
  IAlertsApi,
  NotImplementedAlertsApi,
} from '../../api/alerts/IAlertsApi';
import { FC } from '../../types/general.types';

export type ApiContextProps = {
  authApi: IAuthorizationApi;
  userApi: IUserApi;
  companyApi: ICompanyApi;
  teamApi: ITeamApi;
  feedbackApi: IFeedbackApi;
  gamificationApi: IGamificationApi;
  companyActionApi: ICompanyActionApi;
  alertApi: IAlertsApi;
};

export const ApiContext = createContext<ApiContextProps>({
  authApi: new NotImplementedAuthorizationApi(),
  userApi: new NotImplementedUserApi(),
  companyApi: new NotImplementedCompanyApi(),
  teamApi: new NotImplementedTeamApi(),
  feedbackApi: new NotImplementedFeedbackApi(),
  gamificationApi: new NotImplementedGamificationApi(),
  companyActionApi: new NotImplementedCompanyActionApi(),
  alertApi: new NotImplementedAlertsApi(),
});

export const ApiProvider: FC = ({ children }) => {
  const storageProvider = useStorageProvider();
  const errorHandler = useErrorHandler();
  const [isLoading, setIsLoading] = useState(true);

  const [authApi, setAuthApi] = useState<IAuthorizationApi>(
    new NotImplementedAuthorizationApi()
  );
  const [userApi, setUserApi] = useState<IUserApi>(new NotImplementedUserApi());
  const [companyActionApi, setCompanyActionApi] = useState<ICompanyActionApi>(
    new NotImplementedCompanyActionApi()
  );
  const [companyApi, setCompanyApi] = useState<ICompanyApi>(
    new NotImplementedCompanyApi()
  );
  const [teamApi, setTeamApi] = useState<ITeamApi>(new NotImplementedTeamApi());
  const [feedbackApi, setFeedbackApi] = useState<IFeedbackApi>(
    new NotImplementedFeedbackApi()
  );
  const [gamificationApi, setGamificationApi] = useState<IGamificationApi>(
    new NotImplementedGamificationApi()
  );
  const [alertApi, setAlertApi] = useState<IAlertsApi>(
    new NotImplementedAlertsApi()
  );

  useEffect(() => {
    createApi({
      baseUrl: Config.baseUrl!,
      storageProvider,
      errorHandler,
    }).then(
      ({
        authorization,
        userApi,
        companyApi,
        teamApi,
        feedbackApi,
        gamificationApi,
        companyActionApi,
        alertApi,
      }) => {
        setAuthApi(authorization);
        setUserApi(userApi);
        setCompanyApi(companyApi);
        setTeamApi(teamApi);
        setFeedbackApi(feedbackApi);
        setGamificationApi(gamificationApi);
        setCompanyActionApi(companyActionApi);
        setAlertApi(alertApi);
        setIsLoading(false);
      }
    );
  }, [errorHandler, storageProvider]);

  return (
    <ApiContext.Provider
      value={{
        authApi,
        userApi,
        companyApi,
        teamApi,
        feedbackApi,
        gamificationApi,
        companyActionApi,
        alertApi,
      }}
    >
      {isLoading ? null : children}
    </ApiContext.Provider>
  );
};
