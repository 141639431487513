import React, { ChangeEvent, useCallback, useMemo } from 'react';

type SelectInputProps<T> = {
  className?: string;
  options: T[];
  value: T | undefined | null;
  onValueChange: (newVal: T | undefined | null) => void;
  displayFn: (val: T) => string;
  valueFn: (val: T) => string;
};

const SelectInput = <T,>({
  onValueChange,
  value,
  displayFn,
  valueFn,
  options,
  className,
}: SelectInputProps<T>) => {
  const onSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const foundElement = options.find((el) => valueFn(el) === e.target.value);
      onValueChange(foundElement);
    },
    [onValueChange, options, valueFn]
  );

  const currentValue = useMemo(() => {
    return value ? valueFn(value) : undefined;
  }, [value, valueFn]);

  return (
    <select
      className={`bg-card h-12 rounded-md px-2 focus-visible:outline-0 ${className}`}
      value={currentValue}
      onChange={onSelectChange}
    >
      {options.map((el) => {
        return (
          <React.Fragment key={valueFn(el)}>
            <option value={valueFn(el)}>{displayFn(el)}</option>
          </React.Fragment>
        );
      })}
    </select>
  );
};

export default SelectInput;
