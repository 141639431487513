import React from 'react';
import { FC } from '../types/general.types';

type CardProps = {
  className?: string;
};

const Card: FC<CardProps> = ({ children, className }) => {
  return (
    <div
      className={`px-5 py-5 rounded-3xl shadow-lg w-full bg-white ${className}`}
    >
      {children}
    </div>
  );
};

export default Card;
